<template>
  <div class="container">
    <div class="top">
      <img
        class="img"
        src="../../../../assets/images/Monitor/title.png"
        alt=""
      />
      <div class="top-content">
        <div class="top-left">
          <div class="list">
            <div v-for="(item, index) in topList" :key="index" class="item">
              <div class="item-title">
                <span>{{ item.title }}</span>
                <span>{{ item.num }}台</span>
              </div>
              <div>
                <el-progress
                  :show-text="false"
                  :percentage="item.num"
                  :color="item.color"
                />
              </div>
            </div>
          </div>
          <div class="list" style="margin-top: 10px">
            <div class="item" style="height: 30px">
              <div class="flex">
                <div style="display: flex; align-items: center">
                  <div class="yuan"></div>
                  <div style="color: #fff">实际功率</div>
                </div>
                <div style="color: #02a5ed">708.11w</div>
              </div>
            </div>
            <div class="item" style="height: 30px">
              <div class="flex">
                <div style="display: flex; align-items: center">
                  <div class="yuan"></div>
                  <div style="color: #fff">辐射强度</div>
                </div>
                <div style="color: #02a5ed">0.00w/m²</div>
              </div>
            </div>
            <div class="item" style="height: 30px">
              <div class="flex">
                <div style="display: flex; align-items: center">
                  <div class="yuan"></div>
                  <div style="color: #fff">环境温度</div>
                </div>
                <div style="color: #02a5ed">0℃</div>
              </div>
            </div>
            <div class="item" style="height: 30px">
              <div class="flex">
                <div style="display: flex; align-items: center">
                  <div class="yuan"></div>
                  <div style="color: #fff">逆变器输出电量</div>
                </div>
                <div style="color: #02a5ed">2140.23度</div>
              </div>
            </div>
          </div>
        </div>
        <div class="top-right">
          <div>
            <div id="box" class="charts"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img
        class="img"
        src="../../../../assets/images/Monitor/title.png"
        alt=""
      />
      <div style="margin-top: 20px">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="逆变器名称">
            <el-input v-model="form.user"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary">查询</el-button>
            <!-- <el-button type="primary">还原</el-button> -->
          </el-form-item>
        </el-form>

        <div style="margin-top: 10px">
          <el-row :gutter="20">
            <el-col :span="4" v-for="item in dataList" :key="item">
              <div
                class="item2"
                @click="$router.push('/monitor/monitor/deviceItem')"
              >
                <div class="item-title">
                  <div>{{ item.deviceName }}</div>
                  <div>{{ item.powerStationName }}</div>
                </div>
                <div class="item-con">
                  <div>
                    <img
                      src="../../../../assets/images/Monitor/u154.png"
                      alt=""
                    />
                    <!-- <div class="item-con-title">通讯中断</div> -->
                  </div>
                  <div class="item-con-right">
                    <div class="mt10" style="color: #02a7f0">
                      {{ item.deviceTypeName }}
                    </div>
                    <div class="mt10" style="font-size: 15px">
                      {{ item.currDayPowerNum }}
                      <span style="font-size: 10px"> 度</span>
                    </div>
                    <div style="font-size: 10px; color: #02a7f0">
                      今日发电量
                    </div>
                    <!-- <div>{{ item.deviceNo }}</div>
                    <div>{{ item.measure }}</div>
                    <div>{{ item.modelName }}</div> -->
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { component1 } from "./ehcarts";
import { transDate } from "@/utils/common";
import * as echarts from "echarts";
//
export default {
  data() {
    return {
      dataList: [],
      topList: [
        { title: "正常运行", num: 0, color: "#63a103" },
        { title: "关闭", num: 0, color: "#02a7f0" },
        { title: "故障停机", num: 0, color: "#655f7d" },
        { title: "通讯中断", num: 0, color: "#8c9da8" },
        { title: "告警运行", num: 0, color: "#dc851f" },
        { title: "限电运行", num: 0, color: "#fb4d8c" },
        { title: "含为零支路", num: 0, color: "#ffb93f" },
      ],
      summaryDayList: [],
      form: {},
    };
  },
  created() {
    this.getData();
    this.getSummaryByDays();
  },
  methods: {
    init() {
      setTimeout(() => {
        this.box = echarts.init(document.getElementById("box"));
        let option = component1;
        this.box.setOption(option);
      }, 200);
      window.onresize = function () {
        if (this.box) {
          this.box.resize(); // 调用 ECharts 实例的 resize 方法
        }
      }.bind(this);
    },
    async getData() {
      let data = { powerStationCode: localStorage.getItem("powerStationCode") };
      let res = await this.$http.post("/powerStationDevice/queryList", data);
      if (res.data.code == 200) {
        this.dataList = res.data.data;
        // 运行
        this.topList[0].num = this.dataList.filter(
          (item) => item.runingStatus == 1
        ).length;
        // 关闭
        this.topList[1].num = this.dataList.filter(
          (item) => item.runingStatus == 0
        ).length;
        //故障
        this.topList[2].num = this.dataList.filter(
          (item) => item.runingStatus == 2
        ).length;
        // 通讯中断
        this.topList[3].num = this.dataList.filter(
          (item) => item.connectStatus == 0
        ).length;
      }
    },
    //
    async getSummaryByDays() {
      const summaryDate = transDate(new Date(), "yyyy-MM-dd");
      const res = await this.$http.post(
        "/powerStationDeviceSummary/summaryByDays",
        {
          powerStationCode: "3",
          summaryDate,
        }
      );
      if (res.data.code === 200) {
        this.summaryDayList = res.data.data;
        component1.xAxis.data = res.data.data.map(
          (item) => item.date.split(" ")[1]
        );
        component1.series[0].data = res.data.data.map((item) =>
          Number(item.efficiency > 1000 ?  item.efficiency / 1000 : item.efficiency ).toFixed(2)
        );
        component1.series[1].data = res.data.data.map((item) =>
          Number(item.powerNum).toFixed(2)
        );
        component1.series[2].data = res.data.data.map((item) =>
          Number(item.price ? item.price : 0).toFixed(2)
        );

        //  powerNum
        this.box = echarts.init(document.getElementById("box"));

        this.box.setOption(component1);
      } else {
        this.$message.error(res.data.message);
      }
    },
  },

  mounted() {
    this.init();
  },
  beforeDestory() {
    this.box.dispose();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-progress-bar__outer {
  height: 10px !important;
  background-color: #2d5981 !important;
  border: 1px solid #02a7f0 !important;
}
::v-deep .el-form-item--small .el-form-item__label {
  color: #fff !important;
}
::v-deep .el-input--small .el-input__inner {
  background-color: #2d5981 !important;
  color: #fff !important;
  border: 1px solid #5f809d;
}

.mt10 {
  margin-top: 10px;
}
.item2 {
  height: 250px;
  border: 1px solid #5f809d;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;

  .item-title {
    text-align: center;
    color: #fff;
    border-bottom: 1px solid #4c7396;
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 80px;
  }
  .item-con {
    display: flex;
    width: 200px;
    // justify-content: space-around;
    align-items: center;
    height: 180px;
    .item-con-right {
      // display: flex;
      // flex-direction: column;
      font-size: 16px;
      height: 130px;
      width: 100%;
      text-align: center;
      // justify-content: space-around;
      // align-items: center;
      div {
        color: #fff;
      }
    }
    div {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      margin-left: 2px;
      .item-con-title {
        color: #fff;
        margin-top: 10px;
      }
      img {
        width: 80px;
        // height: 50px;
      }
    }
  }
}

.yuan {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #009fde;
  margin-right: 10px;
}
.container {
  width: 100%;
  .top {
    width: 100%;
    background-color: #2d5981;
    border-radius: 1px;
    padding: 10px;
    // overflow: hidden;
    .img {
      width: 100%;
    }
    .top-content {
      display: flex;
      padding-top: 10px;
      justify-content: space-between;
      .top-left {
        width: 40%;
        .list {
          // height: 80px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          // height: 80px;
        }
        .item {
          margin-top: 10px;
          width: 47%;
          // height: 30px;
          .item-title {
            display: flex;
            justify-content: space-between;
            color: #fff;
            margin-bottom: 10px;
          }
        }
      }
      .top-right {
        width: 57%;
        // height: 50px;
      }
    }
  }
  .bottom {
    background-color: #2d5981;
    border-radius: 1px;
    padding: 10px;
    margin-top: 20px;
    .img {
      width: 100%;
    }
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.charts {
  width: 100%;
  height: 300px;
}

.unit {
  font-size: 12px;
  margin-left: 5px;
}
</style>
