<template>
  <div class="container">
    <Component1 />
  </div>
</template>

<script>
import Component1 from "../components/device/component1.vue";
export default {
  components: { Component1 },
  data() {
    return {
      activeIndex: 0,
      title: "安庆光彩大世界分布式电站",
      list: [
        "安庆光彩大世界分布式电站",
        "安庆光彩大世界分布式电站2",
        "安庆光彩大世界分布式电站3",
        "安庆光彩大世界分布式电站4",
      ],
    };
  },
  created() {
  },

  methods: {
    handleCommand(command) {
      this.activeIndex = command;
      this.title = this.list[this.activeIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dropdown {
  color: #1997f8;
  font-size: 18px;
  border: 1px solid #1997f8;
  padding: 10px;
  border-radius: 02px;
  height: 50px;
  //文本省略

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 2rem;
}

.container {
  background-color: #223f6c;
  padding: 0.25rem;
  // min-height: 100vh;
}
</style>
