import { render, staticRenderFns } from "./component1.vue?vue&type=template&id=4a6b2340&scoped=true"
import script from "./component1.vue?vue&type=script&lang=js"
export * from "./component1.vue?vue&type=script&lang=js"
import style0 from "./component1.vue?vue&type=style&index=0&id=4a6b2340&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a6b2340",
  null
  
)

component.options.__file = "component1.vue"
export default component.exports