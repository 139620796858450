export const component1 = {
  // tooltip: {
  //   trigger: 'axis',
  //   formatter: function (data) {
  //     let html = ``;
  //     html = `
  //       <div>${data[0].marker} ${data[0].seriesName}: ${data[0].value > 1000 ? data[0].value / 1000 + 'kw' : data[0].value + 'w'}</div>
  //       <div>${data[1].marker} ${data[1].seriesName}: ${data[1].value}度</div>
  //       <div>${data[2].marker} ${data[2].seriesName}: ${data[2].value}元</div>
  //       `;
  //     return html;
  //   },
  // },
  tooltip: {
    trigger: 'axis',
    formatter: function (params) {
      let html = '';

      // 遍历 params 数组，params 包含了所有相关系列的数据点信息  
      params.forEach(data => {
        if (data.value != null) { // 确保值不为 null  
          let unit = '';
          switch (data.seriesName) {
            case '实时功率': // 假设这是你的系列名称  
            console.log(data.value,25);
              unit = 'kw';
              break;
            case '发电度数': // 假设这是你的另一个系列名称  
              unit = '度';
              break;
            case '当前收益': // 假设这是你的第三个系列名称  
              unit = '元';
              break;
            default:
              unit = '';
          }

          html += `<div>${data.marker} ${data.seriesName}: ${data.value }${unit}</div>`;
        }
      });

      return html;
    },
  },
  legend: {
    data: ["实时功率", "发电度数", "当前收益",],
    // bottom: 10,
    textStyle: {
      color: "#97b3cb",
    },
    lineStyle: {
      color: "#97b3cb",
    }
  },
  // grid: {
  //   left: "3%",
  //   right: "4%",
  //   bottom:"25%",
  //   containLabel: true,
  // },
  toolbox: {
    feature: {
      saveAsImage: {},
    },
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    axisLabel: {
      show: true,
      textStyle: {
        color: "#97b3cb", // x轴颜色
      },
    },
    axisLine: {
      lineStyle: {
        color: "#fff",
        width: 1,
      },
    },
    data: ["05:00", "06:15", "07:30", "08:45", "10:00", "11:15", "12:30", "13:45", "15:00", "16:15", "17:30", "18:45", "20:00", "21:15"],
  },
  yAxis: {
    type: "value",
    axisLabel: {
      show: true,
      textStyle: {
        color: "#97b3cb", // x轴颜色
      },
    },
    //圆点
    splitLine: {
      lineStyle: {
        type: "dashed", // 线型为虚线
        color: "#4a7195",
      },
    },

    axisLine: {
      lineStyle: {
        color: "#fff",
        width: 1,
      },
    },
  },
  series: [
    {
      name: "实时功率",
      type: "line",
      stack: "Total",
      smooth: true,
      data: [120, 132, 101, 134, 90, 230, 210, 220, 182, 191, 234, 290, 330, 310],
      itemStyle: {
        color: "#00cf99",
      },
      symbol: "circle",
    },
    {
      name: "发电度数",
      type: "line",
      stack: "Total",
      smooth: true,
      data: [220, 182, 191, 234, 290, 330, 310, 120, 132, 101, 134, 90, 230, 210],
      itemStyle: {
        color: "#ffbd00",
      },
      symbol: "circle",
    },
    {
      name: "当前收益",
      type: "line",
      stack: "Total",
      smooth: true,
      data: [150, 232, 201, 154, 190, 330, 410, 120, 132, 101, 134, 90, 230, 210],
      itemStyle: {
        color: "#fb4d8c",
      },
      symbol: "circle",
    },
  ],
};

export const left = {
  tooltip: {
    trigger: 'axis',
    formatter: function (data) {
      // console.log(data,'111111');
      let html = ``;
      html = `
        <div>${data[0].marker} ${data[0].seriesName}: ${data[0].value}MV</div>
        <div>${data[1].marker} ${data[1].seriesName}: ${data[1].value}W/m²</div>
        `;
      return html;
    },
  },
  legend: {
    data: ["交流输出", "辐射强度"],
    // 显示在底部
    bottom: 10,

    textStyle: {
      color: "#97b3cb",
    },
  },
  grid: {
    left: "3%",
    right: "4%",
    containLabel: true,
  },
  toolbox: {
    feature: {
      saveAsImage: {},
    },
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    axisLabel: {
      show: true,
      textStyle: {
        color: "#97b3cb", // x轴颜色
      },
    },
    axisLine: {
      lineStyle: {
        color: "#fff",
        width: 1,
      },
    },
    data: ["05:00", "06:15", "07:30", "08:45", "10:00", "11:15", "12:30", "13:45", "15:00", "16:15", "17:30", "18:45", "20:00", "21:15"],
  },
  yAxis: [
    {
      type: "value",
      name: "kW",
      axisLabel: {
        show: true,
        textStyle: {
          color: "#97b3cb", // x轴颜色
        },
      },
      //圆点
      splitLine: {
        lineStyle: {
          type: "dashed", // 线型为虚线
          color: "#4a7195",
        },
      },

      axisLine: {
        lineStyle: {
          color: "#fff",
          width: 1,
        },
      },
    },
    {
      type: "value",
      name: "W/m²",
      min: 0,
      max: 5,
      axisLabel: {
        show: true,
        textStyle: {
          color: "#97b3cb", // x轴颜色
        },
      },
      // 不显示背景线
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: "#fff",
          width: 1,
        },
      },
    },
  ],
  series: [
    {
      name: "交流输出",
      type: "line",
      stack: "Total",
      smooth: true,
      data: [220, 182, 191, 234, 290, 330, 310, 120, 132, 101, 134, 90, 230, 210],
      itemStyle: {
        color: "#00cf99",
      },
      symbol: "circle",
    },
    {
      name: "辐射强度",
      type: "line",
      stack: "Total",
      smooth: true,
      data: [320, 332, 301, 334, 390, 330, 320, 120, 132, 101, 134, 90, 230, 210],
      itemStyle: {
        color: "#ffbd00",
      },
      symbol: "circle",
    },
  ],
}


export const right = {
  tooltip: {
    trigger: 'axis',
    formatter: function (data) {
      let html = ``;
      html = `
        <div>${data[0].marker} ${data[0].seriesName}: ${data[0].value}MV</div>
        <div>${data[1].marker} ${data[1].seriesName}: ${data[1].value}W/m²</div>
        `;
      return html;
    },
  },
  legend: {
    data: ["PV1", "PV2"],
    // 显示在底部
    bottom: 10,

    textStyle: {
      color: "#97b3cb",
    },
  },
  grid: {
    left: "3%",
    right: "4%",
    containLabel: true,
  },
  toolbox: {
    feature: {
      saveAsImage: {},
    },
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    axisLabel: {
      show: true,
      textStyle: {
        color: "#97b3cb", // x轴颜色
      },
    },
    axisLine: {
      lineStyle: {
        color: "#fff",
        width: 1,
      },
    },
    data: ["05:00", "06:15", "07:30", "08:45", "10:00", "11:15", "12:30", "13:45", "15:00", "16:15", "17:30", "18:45", "20:00", "21:15"],
  },
  yAxis: [
    {
      type: "value",
      name: "kW",
      axisLabel: {
        show: true,
        textStyle: {
          color: "#97b3cb", // x轴颜色
        },
      },
      //圆点
      splitLine: {
        lineStyle: {
          type: "dashed", // 线型为虚线
          color: "#4a7195",
        },
      },

      axisLine: {
        lineStyle: {
          color: "#fff",
          width: 1,
        },
      },
    },
    {
      type: "value",
      name: "W/m²",
      min: 0,
      max: 5,
      axisLabel: {
        show: true,
        textStyle: {
          color: "#97b3cb", // x轴颜色
        },
      },
      // 不显示背景线
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: "#fff",
          width: 1,
        },
      },
    },
  ],
  series: [
    {
      name: "PV1",
      type: "line",
      stack: "Total",
      smooth: true,
      data: [22, 18, 11, 24, 20, 33, 31, 20, 12, 10, 34, 10, 23, 20],
      itemStyle: {
        color: "#fb4d8c",
      },
      symbol: "circle",
    },
    {
      name: "PV2",
      type: "line",
      stack: "Total",
      smooth: true,
      data: [32, 33, 31, 34, 39, 33, 32, 12, 13, 11, 34, 9, 23, 20],
      itemStyle: {
        color: "#ffbd00",
      },
      symbol: "circle",
    },
  ],
}
